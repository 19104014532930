import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Web3 } from "web3";
import DaoABI from "../../constants/DataFeedDAO.json";
import ChainSelector from "@src/components/ChainSelector";
import { PriceContext, ChainContext } from "@src/components/context.js";
import { convertWithFixedRate } from "@src/utils/convert.js";
import CONFIG from "@src/constants/config.json";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const usdPrice = useContext(PriceContext);
  const { chain, contractAddress } = useContext(ChainContext);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [updateCoolDown, setUpdateCoolDown] = useState("");
  const [submissionReward, setSubmissionReward] = useState("");
  const [submissionRewardinUSD, setSubmissionRewardInUSD] = useState("");
  const [dataFreshnessWindow, setDataFreshnessWindow] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const web3Instance = new Web3(window.ethereum);
  const navigate = useNavigate();

  const init = () => {
    const file = "/DataFeedDAO.sol";
    fetch(file)
      .then((response) => response.text()) // Convert the response to text
      .then((text) => {
      }) // Log the file content
      .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const convertToUSD = async () => {
      const rewardInUSD = await convertWithFixedRate(submissionReward * 1e9, usdPrice, 2);
      if (rewardInUSD === "fetchError")
        setSubmissionRewardInUSD("");
      else
        setSubmissionRewardInUSD(`$${rewardInUSD} in USD`);
    }
    convertToUSD();
  }, [submissionReward])

  const handleChainSelector = async (_chainId) => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x" + Number(_chainId).toString(16) }]
        });
        return true;
      } else {
        toast.error('Please install metamask.');
        return false;
      }
    } catch (error) {
      console.log(error);
      if (error.code === 4902) {
        toast.error(`Chain not found, please add the chain from https://chainlist.org/.`);
      } else {
        toast.error('Unknown error happened.');
      }
      return false;
    }
  };

  const handleUpdateCoolDown = (event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      // Regular expression to match only digits
      setUpdateCoolDown(inputValue);
    }
  };

  const handleSubmissionReward = (event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      // Regular expression to match only digits
      setSubmissionReward(inputValue);
    }
  };

  const handleDataFreshnessWindow = (event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      // Regular expression to match only digits
      setDataFreshnessWindow(inputValue);
    }
  };

  const registerApi = async () => {
    if (name === "" || url === "" || updateCoolDown === 0 ) {
      toast.error('Please check your inputs.');
      return;
    }
    setIsLoading(true);
    try {
      const contractABI = DaoABI.abi;
      const contract = new web3Instance.eth.Contract(
        contractABI,
        contractAddress
      );
      if (chain) {
        if (!(await handleChainSelector(CONFIG.networks[chain].chainId))) {
          setIsLoading(false);
          return;
        }
      } else {
        toast.error('Chain not found.');
        setIsLoading(false);
        return;
      }
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const params = [
        name,
        description,
        url,
        parseInt(updateCoolDown, 10),
        parseInt(submissionReward * 1e9, 10),
        parseInt(dataFreshnessWindow, 10),
      ];
      contract.methods.registerAPI(...params).send({
        from: accounts[0],
      }).then(() => {
        toast.success('Registration was successful.');
        setTimeout(() => navigate("/"), 3000);
      }).catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setIsLoading(false));
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  return (
      <Box
        sx={{
          '@media (min-width: 800px)': {
            width: "90%",
            paddingLeft: "60px",
            paddingTop: "50px",
            top: "4%",
            left: "1.5%",
          },
          paddingLeft: '30px',
          paddingTop:'50px',
          paddingBottom: '20px',
          top: "1%",
          left: "0.5%",
          height: "88%",
          position: "absolute",
          display: "flex",
          overflow: 'auto',
          flexDirection: "column",
          gap: "20px",
          borderRadius: "16px",
          backgroundColor: "white"
        }}
      >
        <IconButton
          sx={{ 
            width: "height", 
            position: "absolute", 
            left: "5px", 
            top: "25px",
            '@media (min-width: 800px)': {
              left: "15px"
            }
          }}
          onClick={() => { navigate('/') }}
        >
          <ArrowBackIcon/>
        </IconButton>
        <Box
          className='registerShowPad'
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <h1 style={{ textAlign: "left" }}>Create a new Data DAO</h1>
          <span style={{ fontSize: 18, color: "rgba(0, 0, 0, 0.4)", textAlign: "left" }}>
            Once created, anyone can submit latest data from an API and earn rewards
          </span>
          <Divider variant="standard" sx={{ width: "100%" }} />
          <Box sx={{
              overflow: 'auto',
            }}>
            <Box
              className="registerShowPad"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <h3 style={{ textAlign: "left", fontWeight: "bold", marginTop: "20px", color: "rgb(73, 73, 73)" }}>DAO</h3>
              <TextField
                label="DAO Name"
                id="name"
                size="small"
                value={name}
                variant="outlined"
                InputProps={{sx: {borderRadius: "16px"}}}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
              <TextField
                label="API URL"
                id="url"
                size="small"
                value={url}
                variant="outlined"
                InputProps={{sx: {borderRadius: "16px"}}}
                onChange={(event) => {
                  setUrl(event.target.value);
                }}
              />
              <ChainSelector
                sx={{ float: "left", borderRadius: "16px", width: "100%", textAlign: "left" }}
                handleChainSelector={handleChainSelector}
              />
              <TextField
                fullWidth
                label="Instructions"
                id="description"
                value={description}
                multiline
                rows={4}
                variant="outlined"
                InputProps={{sx: {borderRadius: "16px"}}}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />
            </Box>
            <Box
              className="costRewardTextField"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px"
              }}
            >
              <h3 style={{ textAlign: "left", fontWeight: "bold", marginTop: "10px", color: "rgb(73, 73, 73)" }}>Cost and Rewards</h3>
              <TextField
                fullWidth
                label="Submission Reward"
                id="submissionReward"
                size="small"
                value={submissionReward}
                helperText={ submissionRewardinUSD }
                InputProps={{
                  sx: {borderRadius: "16px"},
                  endAdornment: <InputAdornment position="end">Gwei</InputAdornment>
                }}
                variant="outlined"
                onChange={handleSubmissionReward}
              />
              <TextField
                fullWidth
                label="CoolDown Period"
                id="updateCoolDown"
                size="small"
                value={updateCoolDown}
                InputProps={
                  {
                    sx: {borderRadius: "16px"},
                    endAdornment: <InputAdornment position="end">Sec</InputAdornment>
                  }
                }
                variant="outlined"
                onChange={handleUpdateCoolDown}
              />
              <TextField
                fullWidth
                size="small"
                label="DataFreshnessWindow"
                id="dataFreshnessWindow"
                value={dataFreshnessWindow}
                InputProps={{
                  sx: {borderRadius: "16px"},
                  endAdornment: <InputAdornment position="end">Sec</InputAdornment>
                }}
                variant="outlined"
                onChange={handleDataFreshnessWindow}
              />
              <Button variant="contained" onClick={registerApi} sx={{ 
                  fontSize: "10px", 
                  borderRadius: "8px",
                  backgroundColor: "black",
                  marginTop: "10px", 
                  width:"50%",
                }}
                disabled={isLoading}
              >
                  <CircularProgress size="1rem" sx={{ marginRight: "10px", display: (isLoading ? "block" : "none") }}/>
                <span style={{ fontSize:"16px", paddingRight: "5px" }}>⌘</span> Create
              </Button>
            </Box>
          </Box>
        </Box>
        <ToastContainer />
      </Box>
  );
};

export default Register;
