import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { convertWithFixedRate } from '@src/utils/convert.js';
import humanizeDuration from 'humanize-duration';
import CONFIG from "@src/constants/config.json";
import { PriceContext, ChainContext } from "./context.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(50,50,50)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const APIInfoTable = (props) => {
  const { chain, contractAddress } = useContext(ChainContext);
  const explorerLink = CONFIG.networks[chain].explorerLink;
  const usdPrice = useContext(PriceContext);
  const apiInfo = props.apiInfo;
  const [reward, setReward] = useState(false);

  useEffect(() => {
    const getReward = async () => {
      setReward(await convertWithFixedRate((apiInfo.submissionReward), usdPrice));
    }
    getReward();
  }, []);

  return (
    <TableContainer component={Paper} sx={{ width: "95%", marginTop: "10px", float: "right" }}>
      <Table aria-label="customized table">
        <TableBody>
          <StyledTableRow key={0}>
            <StyledTableCell align="center">Address</StyledTableCell>
            <StyledTableCell align="center">
              <a
                href={`${explorerLink}address/${contractAddress}`}
                style={{ color: "rgba(89, 168, 255, 1)" }}
              >
                {contractAddress}
              </a>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key={1}>
            <StyledTableCell align="center">Name</StyledTableCell>
            <StyledTableCell align="center">{apiInfo.name}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key={2}>
            <StyledTableCell align="center">Instruction</StyledTableCell>
            <StyledTableCell align="center">{apiInfo.description}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key={3}>
            <StyledTableCell align="center">UpdateCoolDown</StyledTableCell>
            <StyledTableCell align="center">
              {
                humanizeDuration(apiInfo.updateCoolDown * 1000, {
                  round: true,
                  largest: 2
                })
              }
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key={4}>
            <StyledTableCell align="center">SubmissionReward</StyledTableCell>
            <StyledTableCell align="center">${reward}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key={6}>
            <StyledTableCell align="center">DataFreshnessWindow</StyledTableCell>
            <StyledTableCell align="center">
            {
                humanizeDuration(apiInfo.dataFreshnessWindow * 1000, {
                  round: true,
                  largest: 2
                })
              }
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key={7}>
            <StyledTableCell align="center">Number of Contributors</StyledTableCell>
            <StyledTableCell align="center">{apiInfo.contributorsCount}</StyledTableCell>
          </StyledTableRow>
          {
            (apiInfo.lastSubmitter !== "N/A") ? (
              <StyledTableRow key={8}>
                <StyledTableCell align="center">Last Submitter</StyledTableCell>
                <StyledTableCell align="center">
                  {Number(apiInfo.lastSubmitter) !== 0 ? (
                    <a
                      href={`${explorerLink}/address/${apiInfo.lastSubmitter}`}
                      style={{ color: "rgba(89, 168, 255, 1)" }}
                    >
                      {apiInfo.lastSubmitter}
                    </a>
                  ) : "No Submit"}
                </StyledTableCell>
              </StyledTableRow>
            ): ""
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default APIInfoTable;
