import axios from "axios";

const convertFromWei = (value, round = 4) => {
	if (value < 1000000) {
		return Math.round(value * (10 ** round)) / (10 ** round) + ' Wei';
	} else if (value < 1000000000000000) {
		return Math.round(value / 1e9 * (10 ** round)) / (10 ** round) + ' Gwei';
	} else {
		return Math.round(value / 1e18 * (10 ** round)) / (10 ** round) + ' Eth';
	}
}
let usdPrice = 0, lastFetchTime = 0;
const convertToUSDFromWei = async (value, round = 1) => {
	try {
		if((Date.now() - lastFetchTime) > 30000 || usdPrice === 0) {
			const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
			if (response === undefined || response.status !== 200) {
				return "N/A";
			}
			lastFetchTime = Date.now();
			usdPrice = response.data.ethereum.usd;
			return Math.round(usdPrice * (value / 1e18) * (10 ** round)) / (10 ** round);
		} else {
			return Math.round(usdPrice * (value / 1e18) * (10 ** round)) / (10 ** round);
		}
	} catch {
		return "N/A";
	}
}

const convertWithFixedRate = (value, rate, round = 1) => {
	const price = Math.round(rate * (value / 1e18) * (10 ** round)) / (10 ** round);
	if (!isNaN(price))
		return price;
	return "N/A";
}

export {
	convertFromWei,
	convertToUSDFromWei,
	convertWithFixedRate
};