import { createContext } from 'react';

// Create a Context
const PriceContext = createContext();

const ChainContext = createContext({
    chain: "sepolia",
    setChain: () => { },
    rpcEndpoint: "",
    contractAddress: ""
 });

export {
    PriceContext,
    ChainContext
};