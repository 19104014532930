import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import Register from "./pages/Register";
import DataFeeder from "./pages/DataFeeder";
import { ChainContext, PriceContext } from "./components/context";
import { convertToUSDFromWei } from "./utils/convert";
import CONFIG from "@src/constants/config.json";

function App() {
  const [usdPrice, setUSDPrice] = useState(0);
  const [chain, setChain] = useState();
  const [rpcEndpoint, setRpcEndpoint] = useState();
  const [contractAddress, setContractAddress] = useState();

  useEffect(() => {
    const fetchPrice = async () => {
      setUSDPrice(await convertToUSDFromWei(1e18));
    }
    fetchPrice();
    setInterval(() => fetchPrice(), 30000);
    if(localStorage.getItem("DataFeedDAO-chainId") !== null)
      setChain(localStorage.getItem("DataFeedDAO-chainId"));
    else setChain(Object.keys(CONFIG.networks)[0]);
  }, []);

  useEffect(() => {
    if(chain !== undefined && CONFIG.networks[chain] !== undefined) {
      setRpcEndpoint(CONFIG.networks[chain]["rpcEndpoint"]);
      setContractAddress(CONFIG.networks[chain]["contractAddress"]);
    }
  }, [chain]);

  return (
    <ChainContext.Provider value={{ chain,setChain, rpcEndpoint, contractAddress }}>
      <PriceContext.Provider value={usdPrice}>
        <Router>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/register" element={<Register />} />
            <Route path="/datafeeder" element={<DataFeeder />} />
          </Routes>
        </Router>
      </PriceContext.Provider>
    </ChainContext.Provider>
  );
}

export default App;
