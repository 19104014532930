import React, { useState, useEffect, useCallback, useContext } from "react";
import { Box, Button, Container, Dialog, DialogTitle, Divider, IconButton, InputAdornment, List, ListItemText, TextField, Toolbar, Tooltip } from "@mui/material";
import { ActiveTable } from 'active-table-react';
import { SpinnerDotted } from 'spinners-react';
import { Web3 } from "web3";
import DaoABI from "../../constants/DataFeedDAO.json";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CodeEditor from '@uiw/react-textarea-code-editor';
import CircularProgress from '@mui/material/CircularProgress';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { ethers } from "ethers";
import { ReclaimClient } from "@reclaimprotocol/zk-fetch";
import CloseIcon from '@mui/icons-material/Close';
import humanizeDuration from 'humanize-duration';
import dateFormat from "dateformat";
import { convertWithFixedRate } from '../../utils/convert.js';
import { convertFromWei } from "../../utils/convert.js";
import CONFIG from "@src/constants/config.json";
import { PriceContext, ChainContext } from "@src/components/context.js";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const DataFeeder = (props) => {
  const { chain, rpcEndpoint, contractAddress } = useContext(ChainContext);
  const explorerLink = CONFIG.networks[chain].explorerLink;
  const apiInfo = props.apiInfo;
  const usdPrice = useContext(PriceContext);
  const [reclaimAppSecret, setReclaimAppSecret] = useState("");
  const [reclaimAppId, setReclaimAppId] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [runCount, setRunCount] = useState(0);
  const [feedScript, setFeedScript] = useState("");
  const [stateBox, setStateBox] = useState("");
  const [stats, setStats] = useState([]);
  const [earning, setEarning] = useState(0);
  const [spent, setSpent] = useState(0);
  const [open, setOpen] = useState(false);
  const [depositAmount, setDepositAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [depositAmountInUSD, setDepositAmountInUSD] = useState(0);

  const [tableData, setTableData] = useState(
    [
      ['Header', 'Data'],
    ]);
  const [deposits, setDeposits] = useState(0);
  const web3Instance = new Web3(rpcEndpoint);
  const contract = new web3Instance.eth.Contract(
    DaoABI.abi,
    contractAddress
  );

  const deposit = async () => {
    setIsLoading(true);
    if (window.ethereum) {
      const walletInstance = new Web3(window.ethereum);
      const contract = new walletInstance.eth.Contract(
        DaoABI.abi,
        contractAddress
      );
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + Number(CONFIG.networks[chain].chainId).toString(16) }]
      });
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      contract.methods.depositFund(apiInfo.url).send({ from: accounts[0], value: depositAmount * 1e9 }).then(() => {
        toast.success('Deposit was successful.');
      }).catch(() => {
        toast.error('Deposit failed.');
      }).finally(() => {
        setIsLoading(false);
        setOpen(false);
      });
    } else {
      toast.warning('Please install metamask.');
    }
  }
  
  const handleDepositAmount = (event) => {
    if (/^\d*$/.test(event.target.value)) {
      setDepositAmount(event.target.value);
      setDepositAmountInUSD(convertWithFixedRate(event.target.value * 1e9, usdPrice, 2));
    }
  }

  const handleDialogClose = () => {
    setOpen(false);
  }

  const openDialog = () => {
    setOpen(true);
  }

  const handleReclaimAppSecret = (event) => {
    const privateKey = event.target.value;
    setReclaimAppSecret(privateKey);
    if (privateKey === "") {
      setStateBox(
        <>
          You can obtain an App Secret (Private Key) from <a href="https://dev.reclaimprotocol.org" style={{color:"rgb(89, 168, 212)"}}>dev.reclaimprotocol.org</a>
        </>
      );
      return;
    }
    try {
      const account = web3Instance.eth.accounts.privateKeyToAccount(privateKey);
      setReclaimAppId(account.address);
      web3Instance.eth.getBalance(account.address)
        .then(balance => {
          if (balance > 1e15) {
            setStateBox(
              <>
                <div className="show-in-desktop">
                  The balance&nbsp;<span> of {account.address} </span>is {convertFromWei(Number(balance))}.
                </div>
                <div className="show-in-mobile">
                  <div>
                      Address: <a href={`${explorerLink}address/${account.address}`}
                        style={{ color: "rgba(89, 168, 255, 1)" }}
                      >
                        {account.address.substring(0, 12) + "..." + account.address.substring(account.address.length - 4)}
                      </a>
                  </div>
                  <div>Balance: {convertFromWei(Number(balance))}</div>
                </div>
              </>
            );
          }
          else {
            setStateBox(
              <Box sx={{
                padding: "10px",
                background: "rgba(229, 236, 246, 0.5)",
                display: "flex", 
                flexDirection: "row"
              }}>
                <InfoOutlinedIcon sx={{ color: "black", fontSize: 16 } }/>
                <Box sx={{ display: "flex", flexDirection: "column", paddingLeft: "3px" }}>
                  <span style={{ color: "black", fontSize: 14 }}>Needs attention, your application wallet running low</span>
                  <Box style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.4)", textAlign: "left" }}>
                    Send ETH to {account.address}
                    <IconButton
                      sx={{ marginBottom:"5px" }}
                      onClick={async () => { 
                        await navigator.clipboard.writeText(account.address);
                      }}
                    >
                      <ContentCopyOutlinedIcon sx={{ fontSize: 12 } }/>
                    </IconButton>
                     to pay for transaction fees
                  </Box>
                </Box>
              </Box>
            )
          }

        }).catch(error => {
          setStateBox(
            <>
              Invalid Address
            </>
          );
        })
      .catch(error => {
          console.error('Error fetching balance:', error);
      });
    } catch {
      setStateBox(
        <>
          Please input a correct secret key.
        </>
      );
    }
  }
  
  const toggleFeeder = () => {
    try{
      web3Instance.eth.accounts.privateKeyToAccount(reclaimAppSecret);
      setIsRunning(!isRunning);
    } catch {
      toast.error('Please input a correct secret key.');
    }
  }

  const runFeeder = useCallback(async () => {
    const info = apiInfo;
    const provider = new ethers.JsonRpcProvider(rpcEndpoint);
    const wallet = new ethers.Wallet(reclaimAppSecret, provider);
    const _contract = new ethers.Contract(
      contractAddress,
      DaoABI.abi,
      wallet
    );
    const reclaimClient = new ReclaimClient(
      reclaimAppId,
      reclaimAppSecret
    );

    const fetchProof = async (url) => {
      const publicOptions = {
        method: "GET",
        headers: {
          accept: "application/json, text/plain, */*"
        }
      };
      const privateOptions = {headers: {}};
      for (var i = 1; i < tableData.length; i++) {
        if (tableData[i][0])
          privateOptions.headers[tableData[i][0]] = tableData[i][1];
      }
      return await reclaimClient.zkFetch(url, publicOptions, privateOptions);
    };
    const transformVerifiedResponse = (claim) => {
      return JSON.parse(
        claim.claimInfo.parameters
      ).responseMatches[0].value.replaceAll('"', '\\"');
    };

    const transformForOnchain = (proof) => {
      const claimInfoBuilder = new Map([
        ["context", proof.claim.context],
        ["parameters", proof.claim.parameters],
        ["provider", proof.claim.provider],
      ]);
      const claimInfo = Object.fromEntries(claimInfoBuilder);
      const claimBuilder = new Map([
        ["epoch", proof.claim.epoch],
        ["identifier", proof.claim.identifier],
        ["owner", proof.claim.owner],
        ["timestampS", proof.claim.timestampS],
      ]);
      const signedClaim = {
        claim: Object.fromEntries(claimBuilder),
        signatures: ["0x" + Buffer.from(proof.signatures.claimSignature).toString('hex')]
      };
      return { claimInfo, signedClaim };
    }
    
    const submitToContract = async (url, claim) => {
      // const transformedClaim = Reclaim.transformForOnchain(claim);
      const transformedClaim = transformForOnchain(claim);
      let verifiedResponse = transformVerifiedResponse(transformedClaim);
    
      return await _contract.submitData(url, verifiedResponse, transformedClaim);
    };
    

    // Function to run every 60 seconds
    const submitData = async (url) => {
      try {
        const claim = await fetchProof(url);
        const result = await submitToContract(url, claim);
        result.wait().then(async () => {
          toast.success('Submission succeeded!');
          contract.methods.getDepositedFund(apiInfo.url).call().then(fund => {
            setDeposits(Number(fund));
          });
          const gasPrice = await web3Instance.eth.getGasPrice();
          const updatedEarning = earning + apiInfo.submissionReward,
            updatedSpent = spent + Number(result.gasLimit * gasPrice);
          setEarning(updatedEarning);
          setSpent(updatedSpent);
          localStorage.setItem(`${chain}-${url}-earning`, updatedEarning);
          localStorage.setItem(`${chain}-${url}-spent`, updatedSpent);
          
          setStats(logs => {
            logs.push(
              <Box style={{ display: "flex" }}>
                <Box className="show-in-desktop" sx={{ width: "25%" }}>{dateFormat(new Date(), "mmmm dS, yyyy, h:MM TT")}</Box>
                <Box className="show-in-mobile" sx={{ width: "35%" }}>{dateFormat(new Date(), "h:MM TT")}</Box>
                <Box className="show-in-desktop" sx={{ width: "55%", marginRight: "5%", overflow: "hidden" }}>
                  <a href={`${explorerLink}tx/${result.hash}`}  style={{color:"black"}} >
                    {result.hash}
                  </a>
                </Box>
                <Box className="show-in-mobile" sx={{ width: "60%", overflow: "hidden" }}>
                  <a href={`${explorerLink}tx/${result.hash}`}  style={{color:"black"}} >
                    {result.hash.substring(0, 10) + "..." + result.hash.substring(result.hash.length - 10)}
                  </a>
                </Box>
                <Tooltip className="show-in-desktop" title={(apiInfo.submissionReward / 1e9) + ' Gwei'}>
                  <Box>${convertWithFixedRate(apiInfo.submissionReward, usdPrice, 2)}</Box>
                </Tooltip>
              </Box>
            );
            return logs;
          });
        }).finally(() => {
          setRunCount(count => count + 1);
        });
      } catch (error) {
        console.log(error);
        if(error.message.indexOf('Invalid receipt') !== -1)
          submitData(info.url);
        else
          setRunCount(count => count + 1);
        // toast(String(error));
        // setStats(logs => {
        //   logs.push(
        //     <Box style={{ display: "flex" }}>
        //       <Box style={{ width: "25%" }}>{dateFormat(new Date(), "mmmm dS, yyyy, h:MM TT")}</Box>
        //       <Box style={{ width: "65%", overflow: "auto" }}>{String(error)}</Box>
        //     </Box>
        //   );
        //   return logs;
        // });
      }
    };
    await submitData(info.url);
  }, [apiInfo, reclaimAppId, reclaimAppSecret, tableData]);

  useEffect(() => {
    const file = "/FeederScript.js";
    fetch(file)
      .then((response) => response.text()) // Convert the response to text
      .then((text) => {
        setFeedScript(text);
      }) // Log the file content
      .catch((error) => console.error("Error:", error));

    setStateBox(
      <>
        You can obtain an App Secret (Private Key) from <a href="https://dev.reclaimprotocol.org" style={{color:"rgb(89, 168, 212)"}}>dev.reclaimprotocol.org</a>
      </>
    );
    return () => {
      setIsRunning(false);
    }
  }, []);

  useEffect(() => {
    setEarning(localStorage.getItem(`${chain}-${apiInfo.url}-earning`));
    setSpent(localStorage.getItem(`${chain}-${apiInfo.url}-spent`));
    contract.methods.getDepositedFund(apiInfo.url).call().then(fund => {
      setDeposits(Number(fund));
    });    
  }, [open, apiInfo.url]);

  useEffect(() => {
    const logList = document.getElementById("logList");
    if (logList.scrollHeight - logList.scrollTop - logList.offsetHeight <= 100)
      logList.scrollTop = logList.scrollHeight;
  }, [stats]);

  useEffect(() => {
    if (isRunning === true) { 
      setStateBox(
        <Box sx={{ display: "flex", placeItems: "center", marginTop: "10px" }}>
          <Box sx={{ fontSize: "18px", marginRight: "20px" }}>Running Submissions</Box>
          <SpinnerDotted size={30} thickness={128} speed={39} color="grey" />
        </Box>
      );
      runFeeder();
    } else {
      clearTimeout();
      if (reclaimAppSecret !== "")
        setStateBox("");
    }
  }, [isRunning]);

  useEffect(() => {
    if (isRunning === true)
      setTimeout(() => runFeeder(), apiInfo.updateCoolDown * 1050);
  }, [runCount]);

  return (
    <Box>
      <Container
        sx={{
          width: "95%",
          position: "absolute",
          justifyContent: "center",
          marginLeft: '10px',
        }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          color: "grey",
          fontSize: '14px',
          fontFamily: "Inter",
        }}>
          <Box sx={{ display: "flex", marginBottom: "-8px", placeItems: "center" }}>
            <Box className="feeder-url-info" sx={{ width:"25%" }}>Url</Box>
            <Box sx={{ display: "flex", placeItems: "center" }}>
              <a href={apiInfo.url} style={{ color: "grey" }}>
                { apiInfo.url.length > 70 
                    ?
                      <Tooltip title={apiInfo.url}>
                        <div>{apiInfo.url.substring(0, 70) + "..."}</div>
                      </Tooltip>
                    :
                      <div>{apiInfo.url}</div>
                  }
              </a>
              <IconButton
                onClick={async () => { 
                  await navigator.clipboard.writeText(apiInfo.url);
                }}
              >
                <ContentCopyOutlinedIcon sx={{ fontSize: 12 } }/>
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ display:"flex", placeItems: "center", height: "17px" }}>
            <Box sx={{ width:"25%", '@media (max-width: 800px)': { width: '60%' } }}>Total Pool</Box>
            <Tooltip title={(deposits / 1e9) + ' Gwei'}>
              <Box sx={{ fontWeight: "bold" }}>${convertWithFixedRate(deposits, usdPrice, 2)}</Box>
            </Tooltip>
            <Tooltip title="Deposit">
              <IconButton 
                sx={{
                  color: "black"
                  }}
                onClick={openDialog}
              >
                <ControlPointIcon 
                  sx={{
                    "animation": "glowing 1500ms infinite"
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: "flex", placeItems: "center" }}>
            <Box sx={{ fontSize: '12px', width:"25%", '@media (max-width: 800px)': { width: '60%' }  }}>Refresh Rate</Box>
            <Box >
              {
                humanizeDuration(apiInfo.updateCoolDown * 1000, {
                  round: true,
                  largest: 2
                })
              }
            </Box>
          </Box>
          <Box sx={{ display: "flex", placeItems: "center" }}>
            <Box sx={{ fontSize: '12px',width:"25%", '@media (max-width: 800px)': { width: '60%' } }}>Number of Contributors</Box>
            <Box >{apiInfo.contributorsCount}</Box>
          </Box>
        </Box>
        <Box
          sx={{
            maxHeight: "400px",
            overflow: "auto",
            display: 'none',
            '@media (min-width: 800px)': {
              width: "90%",
              display: 'block'
            },
            marginTop: "20px",
          }}
        >
          <CodeEditor
            value={feedScript}
            language="js"
            placeholder="Please enter JS code."
            onChange={(evn) => setFeedScript(evn.target.value)}
            padding={15}
            readOnly
            minHeight={300}
            sx={{
              // backgroundColor: "#f5f5f5",
              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
              // , border: "none", "& .MuiOutlinedInput-notchedOutline": { border: "none" }
            }}
          />
        </Box>
        <Box sx={{ fontSize: 15, fontFamily: 'Inter', display: "flex", flexDirection: "column", marginTop: "30px" }}>
          <Box sx={{ color: "grey" }}>
            Instruction
          </Box>
          <pre>{apiInfo.description}</pre>
        </Box>
        <Box
          sx={{
            fontSize: 18,
            fontFamily: 'Inter',
            color: 'grey',
            marginTop: '10px',
            marginBottom: '20px',
            display:"block",
            '@media (min-width: 800px)': {
              display: 'none',
            }
          }}
        >
          Run an oracle from your mobile
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
          <Box sx={{ color: "grey", marginBottom: "10px" }}>
            Secret Header
          </Box>
          <ActiveTable
            displayAddNewRow="false"
            data={tableData}
            style={{width: '90%'}}
            onDataUpdate={async (updatedData) => {
              setTableData(updatedData);
            }}
          />
        </Box>
        <Box sx={{ 
          display: "block",
          width: '90%',
          '@media (min-width: 800px)': {
            display: "flex",
          },
          marginTop: "30px",
          placeItems: "center"
        }}>
          <TextField
            fullWidth
            label="Reclaim zkFetch App Secret"
            id="reclaimAppSecret"
            value={reclaimAppSecret}
            disabled={isRunning}
            InputProps={{ sx: {borderRadius: "16px", width: "98%" }}}
            variant="outlined"
            type="password"
            onChange={handleReclaimAppSecret}
          />
          <Button variant="contained" onClick={toggleFeeder} sx={{ 
              borderRadius: "16px",
              backgroundColor: "black",
              width:"15%",
              padding:"10px",
              '@media (max-width : 800px)': {
                marginTop: '10px',
                width: "30%"
              }
            }}>
              {!isRunning
                ?
              (<><span style={{ fontSize:"16px", paddingRight: "5px" }}>⌘</span>Run</>)
                :
              (<><span style={{ fontSize:"16px", paddingRight: "5px" }}>⌘</span>Stop</>)}
          </Button>
        </Box>
        <Box 
          sx={{
            fontSize: 18,
            fontFamily: 'Inter',
            color: "rgba(0, 0, 0, 0.4)",
            paddingTop: "10px",
            textAlign: "left",
            overflow: "auto",
            '@media (max-width: 800px)': {
              width: "85%",
              marginLeft: '10px',
              marginTop: '10px',
            }
          }}
        >
          {stateBox}
        </Box>
        <Box sx={{
            display: "flex",
            gap: "150px",
            marginTop: "20px"
          }}
        >
          <Box sx={{
            display:"flex",
            flexDirection:"column",
            gap: "10px"
          }}>
            <Box sx={{ fontWeight: "bold", height: "40px", placeContent: "center" }}>Earning</Box>
            <Tooltip title={(earning / 1e9) + ' Gwei'}>
              <Box sx={{ fontWeight: "bold" }}>${convertWithFixedRate(earning, usdPrice, 2)}</Box>
            </Tooltip>
          </Box>

          <Box sx={{
            display:"flex",
            flexDirection:"column",
            gap: "10px"
          }}>
            <Box sx={{ fontWeight: "bold", height: "40px", placeContent: "center" }}>Cost</Box>
            <Tooltip title={(spent / 1e9) + ' Gwei'}>
              <Box sx={{ fontWeight: "bold" }}>${convertWithFixedRate(spent, usdPrice, 2)}</Box>
            </Tooltip>
          </Box>
        </Box>
        <List style={{ height: '300px', overflow: 'auto'}} id="logList">
          {
              stats.map((item, index) => (
              <Box key={index}>
                <ListItemText primary={item}/>
                <Divider variant="standard" component="li" />
              </Box>
            ))
          }
        </List>
      </Container>
      
      <Dialog onClose={handleDialogClose} open={open}>
            <Toolbar sx={{ fontWeight: "bold", backgroundColor: "#161b22", color: "white", justifyContent: "space-between" }}>
              <DialogTitle>Deposit</DialogTitle>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleDialogClose}
                aria-label="close"
                sx={{ flex: "inline" }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <Box sx={{ padding: "20px", gap: "10px", width: "250px", textAlign: "center" }}>
                <TextField
                  fullWidth
                  label="Amount"
                  id="depositAmount"
                  size="small"
                  value={depositAmount}
                  helperText={"$"+depositAmountInUSD + " in USD"}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">Gwei</InputAdornment>
                  }}
                  variant="outlined"
                  onChange={handleDepositAmount}
                />
              <Button sx={{ height: "100%" }} onClick={deposit} disabled = {isLoading}>
                <CircularProgress size="1rem" sx={{ marginRight: "10px", display: (isLoading ? "block" : "none") }}/>
                Deposit
              </Button>
            </Box>
      </Dialog>
      <ToastContainer />
    </Box>
  );
};

export default DataFeeder;
