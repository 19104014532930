import React, { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Paper from "@mui/material/Paper";
import humanizeDuration from 'humanize-duration';
import DataFeeder from "@src/pages/DataFeeder";
import { Box, Collapse, DialogTitle, SwipeableDrawer, IconButton, Tooltip } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp.js'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown.js'
import { convertWithFixedRate } from '@src/utils/convert.js';
import APIInfoTable from "@src/components/APIInfoTable.js";
import { PriceContext } from "@src/components/context";

const useStyles = {
  "showInDesktop": {
    display: "none",
    '@media (min-width: 800px)': {
      display: "table-cell"
    }
  },
  "urlShowInDesktop": {
    display: "none",
    '@media (min-width: 800px)': {
      display: "block"
    }
  },
  "urlShowInMobile": {
    display: "block",
    '@media (min-width: 800px)': {
      display: "none",
    }
  },
  "datafeeder": {
    width: "100%",
    '@media (min-width: 800px)': {
      width: "60%"
    }
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "grey",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DashboardTable = (props) => {
  const classes = useStyles;
  const usdPrice = useContext(PriceContext);
  const rows = props.rows, filter = props.filter;

  const [filteredRows, setFilteredRows] = useState([]);
  const [apiInfo, setAPIInfo] = useState();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  }

  const rowClicked = async (row) => {
    setAPIInfo(row);
    toggleDrawer();
  };

  const humanizeString = (timeGapMs) => {
    if (timeGapMs <= 300 * 1000) {
      return "Just now";
    }
    var timeAgoString = humanizeDuration(timeGapMs, {
      round: true,
      largest: 1
    });
    if (timeAgoString === "1 day") {
      timeAgoString = "Yesterday";
    } else {
      timeAgoString += " ago";
    }
    return timeAgoString;
  }

  useEffect(() => {
    setFilteredRows(rows.filter(row => 
      row.url.toLowerCase().includes(filter)
    ))
  }, [filter, rows]);

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [reward, setReward] = useState(0);

    useEffect(() => {
      const getReward = async () => {
        setReward(await convertWithFixedRate((row.submissionReward * (60 / row.updateCoolDown) / (row.contributorsCount + 1)), usdPrice, 2));
      }
      getReward();
    }, []);

    return (
      <React.Fragment>
        <StyledTableRow
          style={{ cursor: "pointer", backgroundColor: "white" }}
        >
          <StyledTableCell sx={classes.showInDesktop}>
            <Tooltip title="Show details">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell sx={classes.showInDesktop} align="left" onClick={() => rowClicked(row)}>{row.id}</StyledTableCell>
          <StyledTableCell align="left" onClick={() => rowClicked(row)}>
              <Box sx={classes.urlShowInDesktop}>
                { row.url.length > 70 
                  ?
                    <Tooltip title={row.url}>
                      <div>{row.url.substring(0, 70) + "..."}</div>
                    </Tooltip>
                  :
                    row.url
                }
              </Box>
              <Box sx={classes.urlShowInMobile}>
                {row.url.substring(0, 30) + "..."}
              </Box>
          </StyledTableCell>
          <StyledTableCell align="left" onClick={() => rowClicked(row)}>${reward}/min</StyledTableCell>
          <StyledTableCell sx={classes.showInDesktop} align="left" onClick={() => rowClicked(row)}>
            <Box sx={{ display: "flex", placeContent: "middle" }}>
              <CalendarTodayOutlinedIcon fontSize="small" sx={{ paddingRight: "5px" }}/>
              <div>{row.lastSubmissionTime !== 0 ? humanizeString(Date.now() - row.lastSubmissionTime) : "No submit"}</div>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <APIInfoTable apiInfo={row}/>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell sx={classes.showInDesktop}/>
              <StyledTableCell sx={classes.showInDesktop} align="left">ID</StyledTableCell>
              <StyledTableCell align="left">URL</StyledTableCell>
              <StyledTableCell align="left">Expected Returns</StyledTableCell>
              <StyledTableCell sx={classes.showInDesktop} align="left">Last Update</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row, idx) => (
              <Row key={idx} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        onOpen={() => {}}
        variant="temporary"
        PaperProps={{
          sx: classes.datafeeder,
        }}
      >
        <DialogTitle>
          <div style={{ fontSize: 48 }}>Run & Earn</div>
          <Box sx={{ fontSize: 18, color: "rgba(0, 0, 0, 0.4)", '@media (max-width: 800px)': { display: "none" } }}>
            Run this script to submit the latest data to the DAO and earn
          </Box>
        </DialogTitle>
         {apiInfo !== undefined ? <DataFeeder apiInfo={apiInfo}/>: ""}
      </SwipeableDrawer>
    </>
  );
};

export default DashboardTable;
