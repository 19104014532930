import React, { useEffect, useState, useContext } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CONFIG from "@src/constants/config.json";
import { ChainContext } from "@src/components/context"

const ChainSelector = (props) => {
    let { chain, setChain } = useContext(ChainContext)
    const [chains, setChains] = useState([]);

    const handleChainSelector = (event) => {
        setChain(event.target.value);
        localStorage.setItem("DataFeedDAO-chainId", event.target.value);
        if (props.handleChainSelector) {
            props.handleChainSelector(CONFIG.networks[event.target.value].chainId);
        }
    }

    useEffect(() => {
        setChains(Object.keys(CONFIG.networks));
    }, []);

    return (
        <FormControl sx={props.sx ? props.sx : {}}>
            <InputLabel id="demo-simple-select-label">Network</InputLabel>
            <Select
                id="chainSelector"
                size="small"
                sx={{ borderRadius: props.sx.borderRadius }}
                value={chain !== undefined ? chain : ""}
                label="Network"
                onChange={handleChainSelector}
            >
                { 
                    chains.map((chain, key) => 
                        <MenuItem key={key} value={chain}>
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <img
                                    src={`./chainIcons/${CONFIG.networks[chain].icon}`}
                                    width="20px"
                                    height="20px"
                                    alt="support icon"
                                />
                                {CONFIG.networks[chain].name}
                            </Box>
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    );
}

export default ChainSelector;