import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Divider, InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Web3 } from "web3";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import DashboardTable from "../../components/DashboardTable";
import DaoABI from "../../constants/DataFeedDAO.json";
import { ChainContext } from "@src/components/context";
import ChainSelector from "@src/components/ChainSelector";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
  let { rpcEndpoint, contractAddress } = useContext(ChainContext)
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  let navigate = useNavigate();

  const goToRegister = () => {
    navigate("/register");
  };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value.toLowerCase());
  }

  const init = async () => {
    const web3Instance = new Web3(rpcEndpoint);
    setIsLoading(true);
    try {
      const contractABI = DaoABI.abi;
      const contract = new web3Instance.eth.Contract(
        contractABI,
        contractAddress
      );
      const apiListLength = await contract.methods.getAPIListLength().call();
      let data = [];
      for (let i = 0; i < apiListLength; i++) {
        const url = await contract.methods.getAPIUrlfromID(i).call();
        const apiInfo = await contract.methods.getAPIInfo(url).call();
        data.push({
          id: i,
          url,
          name: apiInfo[0],
          description: apiInfo[1],
          updateCoolDown: Number(apiInfo[2]),
          submissionReward: Number(apiInfo[3]),
          dataFreshnessWindow: Number(apiInfo[4]),
          currentTick: Number(apiInfo[5]),
          contributorsCount: Number(apiInfo[6]),
          lastSubmitter: apiInfo[7],
          lastSubmissionTime: Number(apiInfo[8]) * 1000
        });
      }
      setRows(data);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching API list");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (rpcEndpoint === undefined ) return;
    init();
  }, [rpcEndpoint]);

  return (
    <div>
        <Box
          sx={{
            width: "95%",
            height: "88%",
            top: "4%",
            left: "1.5%",
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            borderRadius: "16px",
            backgroundColor: "white",
            paddingLeft: "10px",
            paddingTop: "10px",
            overflow: 'auto',
            '@media (min-width: 800px)': {
              width: "90%",
              paddingLeft: "60px",
              paddingTop: "50px"
            }
          }}
        >
          <Box sx={{ 
            fontWeight: "bold", 
            fontSize: 60, 
            '@media (max-width: 800px)': { 
              fontSize: 40 ,
              marginLeft: '10px'
              }
            }}>
              Data DAOs
          </Box>
          <Box sx={{
            fontSize: 18,
            color: "rgba(0, 0, 0, 0.4)",
            '@media (max-width: 800px)': {
              marginLeft: '12px'
            }
          }}>
            Permissionlessly feed data to on-chain applications without a trusted Oracle
          </Box>
          
          <Divider variant="standard" sx={{ width: "70%" }} />
          <Box className="showpad" sx={{ height: "70%", overflow: "auto", paddingTop: '20px' }}>
            <Box sx={{ placeItems: "center" }}>
              <TextField 
                id="searchInput" 
                label="URL Search" 
                value={searchInput}
                onChange={handleSearchInput}
                variant="outlined"
                sx={{
                  width: "230px",
                  backgroundColor:"rgba(250, 250, 250)",
                  '@media (max-width: 800px)': {
                    width: "170px",
                  }
                }} 
                size="small" 
                type="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>                
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                    ⌘/
                    </InputAdornment>
                  )
                }}
              />
              <Button 
                onClick={goToRegister}
                variant="contained"
                sx={{
                  width: "fit content",
                  fontSize: "10px", 
                  borderRadius: "8px",
                  backgroundColor: "black",
                  marginLeft: '10px',
                  '@media (max-width: 800px)': {
                    float:"right",
                    marginRight: "5px"
                  }
                }}
              >
                <div className="show-in-desktop">
                  <span style={{ fontSize:"16px", paddingRight: "5px" }}>⌘</span> Create New DAO
                </div>
                <div className="show-in-mobile">
                  <span style={{ fontSize:"16px", paddingRight: "5px" }}>⌘</span> New DAO
                </div>
              </Button>
              <ChainSelector sx={{
                float: "right",
                marginTop: "20px",
                width: '100%',
                '@media (min-width: 800px)': {
                  marginTop: "0px",
                  width: "200px",
                }
                }}
              />
            </Box>
            {isLoading ? 
              <Box sx={{ paddingTop: "50px", marginTop: "50px", width: "100%", textAlign: "center" }}>
                <CircularProgress />
              </Box>
              : 
              <DashboardTable rows={rows} filter={searchInput} />
            }
          </Box>
        </Box>
        <ToastContainer/>
    </div>
  );
};

export default Dashboard;
